<template>
   <div class="mmbottom">
    <span>魔脉提供技术支持</span>
  </div>
</template>
<style lang="less" scoped>
.mmbottom {
  color: #888;
  height: 1.5rem;
  margin-bottom: 4.5rem;
  margin-top: .5rem;
  width: 100%;
  text-align: center;
}
</style>
